<template>
  <div class="text-jooycar-gray-dark">
    <div class="flex justify-end px-4 pt-4">
      <button @click="$emit('close')">
        <inline-svg
          :src="require('../assets/images/icons/close.svg')"
          class="text-jooycar-blue-darkest"
        />
      </button>
    </div>
    <div class="pb-24 sm:px-16">
      <table class="w-full">
        <caption class="mb-16 text-2xl font-bold text-center text-jooycar-blue-dark">
          Asistencias
        </caption>
        <tr>
          <th scope="col" />
          <th
            scope="col"
            class="py-3 text-lg font-bold text-center text-jooycar-blue-dark"
          >
            Básica
          </th>
          <th
            scope="col"
            class="py-3 text-lg font-bold text-center text-jooycar-blue-dark"
          >
            Full
          </th>
        </tr>
        <assistances-table-row
          v-for="({ text, borderTop, basic, full }, index) in assistances"
          :key="index"
          :border-top="borderTop || false"
          :second-cell-check="basic"
          :third-cell-check="full"
          :text="text"
        />
      </table>
    </div>
  </div>
</template>

<script>
import AssistancesTableRow from './assistances-table-row';

export default {
  components: { AssistancesTableRow },
  data() {
    return {
      assistances: [
        {
          text: 'Remolque o transporte del vehiculo',
          borderTop: true,
          basic: true,
          full: true,
        },
        {
          text: 'Mecanica Ligera',
          basic: true,
          full: true,
        },
        {
          text: 'Pago grua de carabineros',
          basic: true,
          full: true,
        },
        {
          text: 'Estancia y desplazamiento de los asegurados por inmovilización del vehículo',
          basic: true,
          full: true,
        },
        {
          text: 'Estancia y desplazamiento de los asegurados por robo del vehículo',
          basic: true,
          full: true,
        },
        {
          text: 'Transporte, Deposito o Custodia del vehículo reparado o recuperado',
          basic: true,
          full: true,
        },
        {
          text: 'Servicio de conductor profesional de Emergencia',
          basic: true,
          full: true,
        },
        {
          text: 'Localización y envío de piezas de recambio (piezas son de cargo del asegurado)',
          basic: true,
          full: true,
        },
        {
          text: `Transporte o repatriación sanitaria en caso de lesiones por accidente de tránsito del vehículo
            asegurado`,
          basic: true,
          full: true,
        },
        {
          text: `Transporte o repatración sanitaria de los acompañantes en caso de accidente de tránsito del vehículo
            asegurado`,
          basic: true,
          full: true,
        },
        {
          text: `Transporte o repatriación del asegurado fallecido y de los demás acompañantes, producto de un accidente
            de tránsito que afecte al vehículo asegurado`,
          basic: true,
          full: true,
        },
        {
          text: 'Asistencia Legal en Gestiones de Excarcelacion y Defensa Judicial',
          basic: true,
          full: true,
        },
        {
          text: 'Anticipo de Fianzas',
          basic: true,
          full: true,
        },
        {
          text: 'Asistencia Psicologica',
          basic: false,
          full: true,
        },
        {
          text: 'Reembolso de gastos médicos',
          basic: false,
          full: true,
        },
        {
          text: 'Asistencia Legal Telefonica',
          basic: false,
          full: true,
        },
        {
          text: 'Servicio Revision Tecnica Asistida (Chofer)',
          basic: false,
          full: true,
        },
        {
          text: 'Conductor Profesional de Reemplazo (Ingesta de alcohol)',
          basic: false,
          full: true,
        },
        {
          text: 'Servicio Mecanica Pre Viaje',
          basic: false,
          full: true,
        },
        {
          text: 'Chofer para mantencion de kilometraje de marca',
          basic: false,
          full: true,
        },
        {
          text: 'Llave de Reemplazo',
          basic: false,
          full: true,
        },
        {
          text: 'Entrega o retiro de Vehiculo de reemplazo a domicilio',
          basic: false,
          full: true,
        },
        {
          text: 'Traslado de vehiculo asegurado a taller, para reparacion',
          basic: false,
          full: true,
        },
        {
          text: 'Entrega o retiro de Vehiculo a aeropuerto',
          basic: false,
          full: true,
        },
        {
          text: 'Traslado vehículo a ciudad de origen',
          basic: false,
          full: true,
        },
        {
          text: 'Rescate',
          basic: false,
          full: true,
        },
      ],
    };
  },
};
</script>
