<template>
  <tr>
    <td
      class="h-16 py-2 pl-5 pr-20 text-sm border-b text-jooycar-blue-darkest"
      :class="{ 'border-t': borderTop }"
    >
      {{ text }}
    </td>
    <td
      class="w-40 h-16 py-2 text-center border-b"
      :class="{ 'border-t': borderTop }"
    >
      <inline-svg
        :src="require('../assets/images/icons/check.svg')"
        class="inline w-5 h-4 text-jooycar-blue-sky"
        v-if="secondCellCheck"
      />
    </td>
    <td
      class="w-40 h-16 py-2 text-center border-b"
      :class="{ 'border-t': borderTop }"
    >
      <inline-svg
        :src="require('../assets/images/icons/check.svg')"
        class="inline w-5 h-4 text-jooycar-blue-sky"
        v-if="thirdCellCheck"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    borderTop: { type: Boolean, default: false },
    secondCellCheck: { type: Boolean, required: true },
    thirdCellCheck: { type: Boolean, required: true },
    text: { type: String, required: true },
  },
};
</script>
