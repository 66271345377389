<template>
  <div class="text-jooycar-gray-dark">
    <div class="flex justify-end px-4 pt-4">
      <button @click="$emit('close')">
        <inline-svg
          :src="require('../assets/images/icons/close.svg')"
          class="text-jooycar-blue-darkest"
        />
      </button>
    </div>
    <div class="text-lg font-semibold text-center text-jooycar-blue-dark">
      Detalle de precio base mensual
    </div>
    <div class="flex flex-col px-12 divide-y divide-gray-400">
      <div class="mb-4">
        <p class="my-4 text-sm font-semibold">
          PRECIO FIJO
        </p>
        <div
          class="flex justify-between py-1 text-xs"
        >
          <div>Precio Fijo Base</div>
          <div class="font-semibold text-black">
            {{ basePrice | formatCurrency }}
          </div>
        </div>
        <div
          v-for="(optional, index) in selectedOptionals"
          :key="index"
          class="flex justify-between py-1 text-xs"
        >
          <div>{{ optional.name }}: {{ optional.value }}
            <template v-if="optional.name == 'Asistencia'">
              <button
                class="font-bold underline"
                v-close-popover
                @click="showAssistancesModal"
              >
                ver detalle aquí
              </button>
            </template>
          </div>
          <div class="font-semibold text-black">
            {{ convertPrice(optionalFixedPrice(optional)) | formatCurrency }}
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center py-4">
        <div class="text-xs">
          Precio base total mensual
        </div>
        <div class="text-xl font-bold text-black">
          {{ totalPrice | formatCurrency }}
        </div>
        <div class="mb-4 text-xs text-jooycar-gray-medium">
          {{ ufPrice }} UF
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatCurrency from '../filters/formatCurrency';
import AssistancesModal from '../components/assistances-modal';

const UF_PRICE_DECIMAL_PLACES = 5;

export default {
  props: {
    selectedOptionals: {
      type: Array,
      required: true,
    },
    currencyRate: {
      type: Number,
      required: true,
    },
    basePrice: {
      type: Number,
      required: true,
    },
  },
  methods: {
    convertPrice(price) {
      return price * this.currencyRate;
    },
    optionalFixedPrice(optional) {
      return optional.relatedOptionals.reduce((acc, { fixedPrice }) => acc + fixedPrice, 0);
    },
    showAssistancesModal() {
      this.$modal.show(AssistancesModal, {}, {
        height: 'auto',
        width: '100%',
        maxWidth: 1024,
        scrollable: true,
        adaptive: true,
      });
    },
  },
  filters: {
    formatCurrency(value) {
      return formatCurrency(value);
    },
  },
  computed: {
    totalPrice() {
      const optionalsFixedPrice = this.selectedOptionals.reduce(
        (acc, optional) => acc + this.optionalFixedPrice(optional), 0,
      );

      return this.basePrice + this.currencyRate * (optionalsFixedPrice);
    },
    ufPrice() {
      return (this.totalPrice / this.currencyRate).toFixed(UF_PRICE_DECIMAL_PLACES);
    },
  },
};
</script>
