<template>
  <div
    class="w-full mb-2"
    :class="{ 'max-w-2xl self-center shadow-lg': variant === 'summary' }"
  >
    <div
      v-if="variant === 'summary'"
      class="flex items-center justify-center w-full h-8 mx-auto mb-8 text-white text-md bg-jooycar-blue-sky"
    >
      <p>
        Plan seleccionado
      </p>
    </div>
    <div class="flex flex-col justify-center mb-5 sm:flex-row">
      <div class="flex flex-col items-center">
        <div
          class="text-xs"
          :class="priceDescriptionClass"
        >
          Precio base mensual
        </div>
        <div
          class="flex items-baseline text-center flex-col"
          :class="priceClass"
        >
          <div>
            <span v-if="showPromo">
              {{ promoValue | formatCurrency }}
            </span>
            <span v-else>
              {{ planFixedPrice | formatCurrency }}
            </span>
            <span
              v-if="variant === 'default'"
              class="text-base ml-1"
            >
              CLP
            </span>
          </div>
          <div
            v-if="showPromo"
            class="mx-auto line-through text-2xl -mt-3 mb-2 text-gray-600"
          >
            {{ planFixedPrice | formatCurrency }}
          </div>
        </div>
        <div
          style="
            width: 80%;
            border-radius: 30px;
          "
          class="
            py-2
            text-white
          "
          :class="[variant === 'default' ? 'bg-jooycar-blue-dark' : '']"
        >
          <div
            class="text-xs text-center"
            :class="priceDescriptionClass"
          >
            Fijos adicionales
          </div>
          <div
            class="text-1s text-center font-bold"
            :class="priceClass"
          >
            <span>
              + {{ animatedFixedPrice | formatCurrency }}
            </span>
            <span
              v-if="variant === 'default'"
              class="text-base ml-1"
            >
              CLP
            </span>
          </div>
          <div
            class="text-center text-sm font-semibold cursor-pointer text-white"
            @click="showDetailsModal"
            v-if="variant ==='default'"
          >
            Ver detalle
          </div>
        </div>
      </div>
      <div
        class="text-4xl font-bold mx-5 text-jooycar-blue-sky plus-separator"
        :class="variant ==='default' ? 'mt-5' : 'mt-2'"
      >
        +
      </div>
      <div class="flex flex-col items-center">
        <div
          class="text-xs"
          :class="priceDescriptionClass"
        >
          Precio base Variable
        </div>
        <div
          class="flex flex-col items-center bg-white"
          :class="[
            priceClass,
            variant != 'summary' ? 'text-jooycar-blue-sky w-40' : ''
          ]"
        >
          <span>
            {{ animatedDistancePrice | formatCurrency }}
            <span v-if="variant === 'summary'">por km</span>
            <span
              v-else
              class="-ml-1 text-base"
            >
              CLP*
            </span>
          </span>
          <div
            class="block text-xs font-normal text-jooycar-gray"
            v-if="variant === 'summary'"
          >
            Ajuste CLP en función a la UF
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="variant === 'default'"
      class="self-center text-xs text-center text-jooycar-blue-darkest"
    >
      *Ajuste CLP en función a la UF
    </div>
    <div
      v-else
      class="self-center mb-8 text-xs text-center text-jooycar-blue-darker"
    >
      Se agrega el costo de despacho del dispositivo por un valor único de $5.500
    </div>
  </div>
</template>

<script>
import _get from 'lodash.get';
import TWEEN from 'tween.js';
import formatCurrency from '../filters/formatCurrency';
import PriceDetailModal from '../components/price-detail-modal';
import quotePromo from '../config/quotePromo';

const DEFAULT_UF = 28000;
const DEFAULT_VARIABLE = 20;
const DEFAULT_FIXED = 1500;
const ANIMATION_DURATION = 500;

export default {
  props: {
    plan: { type: Object, required: true },
    distanceFactor: { type: Number, required: true },
    optionals: { type: Array, required: true },
    kilometers: { type: Number, default: 0 },
    fixedPriceOptionals: { type: Array, default: () => [] },
    variant: { type: String, default: 'default' },
  },
  filters: {
    formatCurrency(value) {
      return formatCurrency(value);
    },
  },
  data() {
    return {
      animatedFixedPrice: DEFAULT_FIXED,
      animatedDistancePrice: DEFAULT_VARIABLE,
      showPromo: quotePromo.SHOW_PROMO,
      promoValue: quotePromo.PROMO_VALUE,
    };
  },
  mounted() {
    this.$emit('total-price-change', this.totalPrice);
  },
  methods: {
    showDetailsModal() {
      this.$modal.show(PriceDetailModal, {
        selectedOptionals: this.fixedPriceOptionals,
        currencyRate: this.currencyRate,
        basePrice: this.planFixedPrice,
      }, {
        height: 'auto',
        width: '100%',
        maxWidth: 670,
        scrollable: true,
        adaptive: true,
      });
    },
    tween(changedValue, startValue, endValue) {
      // next check is disabled because _this_ is overridden by tween
      // eslint-disable-next-line consistent-this
      const vm = this;
      function animate() {
        if (TWEEN.update()) {
          requestAnimationFrame(animate);
        }
      }

      new TWEEN.Tween({ tweeningValue: startValue })
        .to({ tweeningValue: endValue }, ANIMATION_DURATION)
        .onUpdate(function () {
          vm[changedValue] = this.tweeningValue.toFixed(0);
        })
        .start();

      animate();
    },
  },
  computed: {
    currencyRate() {
      if (this.plan && this.plan.extraCurrencies) {
        return parseFloat(_get(this.plan.extraCurrencies[0], 'currencyRate', DEFAULT_UF));
      }

      return DEFAULT_UF;
    },
    maxDistance() {
      return this.plan ? parseFloat(_get(this.plan, 'maxDistance')) : null;
    },
    maxPrice() {
      return this.plan ? parseFloat(_get(this.plan, 'maxPrice')) : null;
    },
    additionalFixedPrice() {
      return this.optionals.reduce((acc, { fixedPrice }) => acc + fixedPrice, 0);
    },
    planFixedPrice() {
      return this.currencyRate * parseFloat(_get(this.plan, 'fixedPrice', 1));
    },
    fixedPrice() {
      return this.plan ? this.currencyRate * (this.additionalFixedPrice) : DEFAULT_FIXED;
    },
    additionalDistancePrice() {
      return this.optionals.reduce((acc, { distancePrice }) => acc + distancePrice, 0);
    },
    planDistancePrice() {
      return parseFloat(_get(this.plan, 'distancePrice', 1));
    },
    distancePrice() {
      return this.plan ?
        this.currencyRate * (this.planDistancePrice * this.distanceFactor + this.additionalDistancePrice) :
        DEFAULT_VARIABLE;
    },
    totalPrice() {
      this.$emit('calculated-price-change', {
        variable: Math.round(this.distancePrice),
        additional: Math.round(this.fixedPrice),
        fixed: Math.round(this.planFixedPrice),
      });
      return (
        this.planFixedPrice +
        this.fixedPrice +
        this.distancePrice * Math.min(this.kilometers, this.maxDistance)
      );
    },
    priceDescriptionClass() {
      const classes = {
        'default': 'text-jooycar-gray-darkest font-semibold',
        'summary': 'text-jooycar-blue-darker font-semibold',
      };

      return classes[this.variant];
    },

    priceClass() {
      const classes = {
        'default': 'font-semibold text-5xl',
        'summary': 'text-jooycar-blue-dark text-2xl',
      };

      return classes[this.variant];
    },
  },
  watch: {
    totalPrice() {
      this.$emit('total-price-change', this.totalPrice);
    },
    distancePrice: {
      immediate: true,
      handler(newValue, oldValue) {
        this.animatedPrice = oldValue || DEFAULT_VARIABLE;
        this.tween('animatedDistancePrice', oldValue || DEFAULT_VARIABLE, newValue);
      },
    },
    fixedPrice: {
      immediate: true,
      handler(newValue, oldValue) {
        this.animatedPrice = oldValue || DEFAULT_FIXED;
        this.tween('animatedFixedPrice', oldValue || DEFAULT_FIXED, newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @media (max-width: 639px) {
    .plus-separator {
      text-align: center;
      margin-top: 0;
    }
  }
</style>
